import { connect } from "react-redux";
import React, { useRef, useState } from "react";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import enhancer from "./enhancer/RoomEnhancer";
import Select from "react-select";
import { compose } from "redux";
import { getVenueAllStaffData } from "services/staffServices";
import { addRoom, editRoom } from "services/roomServices";
import NavigationActions from "redux/navigation/actions";
import { useEffect } from "react";
import Loader from "components/Loader";
import { getServiceAllData } from "services/serviceServices";
import { Tag } from "react-feather";
import { VisibilityOff } from "@mui/icons-material";
import { Visibility } from "@mui/icons-material";
import { getAllVenues } from "services/venueServices";
const { success, error } = NavigationActions;

const RoomModal = props => {
  const { isEdit, onClose } = props;
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    token,
    setFieldValue,
    isValid,
    setValues,
    handleSubmit,
    setFieldError,
    toggleRefresh,
    editData
  } = props;

  const fileInputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [venueData, setVenueData] = useState([]);
  const [togglePassword, setTogglePassword] = useState(false);

  useEffect(() => {
    isEdit &&
      setValues({
        ...editData,
        venue_data: editData?.venue_data?._id || editData?.venue_data
      });
  }, [editData]);

  const getVenuesData = async () => {
    await getAllVenues(token)
      .then(res => {
        let venueOptions = res.data.map(venue => {
          return { value: venue._id, label: venue.name };
        });
        setVenueData(venueOptions);
      })
      .catch(err => {
        error(err.message);
      });
  };

  const getStaffData = async vanueId => {
    await getVenueAllStaffData(token, vanueId)
      .then(res => {
        let staffArr = res.data?.map((val, i) => {
          return { value: val._id, label: val.name };
        });
        setStaffData(staffArr);
      })
      .catch(err => {
        error(err.message);
      });
  };
  const getServiceData = async () => {
    await getServiceAllData(token)
      .then(res => {
        // console.log(res, "checkres");
        let serviceArr = res.data?.map((val, i) => {
          return { value: val._id, label: val.name };
        });
        setServiceData(serviceArr);
      })
      .catch(err => {
        error(err.message);
      });
  };
  useEffect(() => {
    getServiceData();
    getVenuesData();
  }, []);

  useEffect(() => {
    if (values?.venue_data) {
      getStaffData(values?.venue_data);
    }
  }, [values?.venue_data]);

  const getRandomPassword = () => {
    var length = 8,
      charset = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setFieldValue("password", retVal);
  };
  useEffect(() => {
    !isEdit && getRandomPassword();
  }, []);
  // console.log("values", values);
  // console.log("errors", errors);
  const RoomHandler = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      var formData = new FormData();
      formData.append("file_image", values.file_image);
      formData.append("name", values.name);
      formData.append("username", values.username.toLowerCase());
      formData.append("password", values.password);
      formData.append("staff_data", JSON.stringify(values.staff_data));
      formData.append("service_data", JSON.stringify(values.service_data));
      formData.append("venue_data", values.venue_data);
      formData.append("status", values.status);
      setLoading(true);

      isEdit
        ? await editRoom(token, values._id, formData).then(res => {
            if (res.success) {
              success(res.message);
              toggleRefresh(true);
              onClose();
              setLoading(false);
            } else {
              error(res.message);
              setLoading(false);
            }
            setLoading(false);
          })
        : await addRoom(token, formData).then(res => {
            if (res.success) {
              success(res.message);
              toggleRefresh(true);
              onClose();
              setLoading(false);
            } else {
              error(res.message);
              setLoading(false);
            }
            setLoading(false);
          });
    }
  };

  const handleImageChange = e => {
    if (e.target.files.length !== 1) {
      return;
    }
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    // Check if the selected file type is allowed
    if (!allowedTypes.includes(file.type)) {
      setFieldError(
        e.target.name,
        "Only .jpg, .jpeg, .png files are allowed."
      );
      // error("Only .jpg, .jpeg, .png files are allowed.");
      e.target.value = "";

      return;
    }
    // check if file is less than 2MB
    if (file.size > 2 * 1024 * 1024) {
      setFieldError(
        e.target.name,
        "File size should be less than 2MB."
      );
      // error("File size should be less than 2MB.");
      e.target.value = "";
      return;
    }

    // Set the selected file in Formik if the file type is valid
    setFieldValue(e.target.name, file);
    fileInputRef.current.value = "";
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Room
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group">
            <label>
              Name <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
              placeholder="Room Name"
            />
            <Error field="name" />
          </div>
          <div className="form-group">
            <label>
              Username <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="username"
              onChange={handleChange}
              value={values.username}
              onBlur={handleBlur}
              placeholder="Username"
              disabled={isEdit ? true : false}
            />
            <Error field="username" />
          </div>
          <div className="form-group">
            <div className="row align-items-center">
              <div className="col">
                <label>
                  Password <span className="red">*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    type={togglePassword ? "text" : "password"}
                    className="form-control react-form-input"
                    id="password"
                    onChange={handleChange}
                    value={values.password}
                    onBlur={handleBlur}
                    placeholder="Room password"
                  />
                  <div className="input-group-append">
                    {togglePassword ? (
                      <VisibilityOff onClick={() => setTogglePassword(false)} />
                    ) : (
                      <Visibility
                        onClick={() => {
                          setTogglePassword(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                <Error field="password" />
              </div>
              <div className="col-auto">
                <button
                  className="generate_btn"
                  onClick={e => {
                    e.preventDefault();
                    getRandomPassword();
                  }}
                  title="Generate New Password"
                >
                  <Tag />
                </button>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>
              Venue <span className="red">*</span>
            </label>
            <Select
              value={venueData?.find(val => {
                return values?.venue_data === val.value;
              })}
              name="venue_data"
              options={venueData}
              className="basic-multi-select w-100"
              classNamePrefix="select"
              onChange={selected => {
                if (selected && selected !== null) {
                  // console.log("selected", selected);
                  setFieldValue("venue_data", selected.value);
                } else {
                  setFieldValue("venue_data", "");
                }
                setFieldValue("staff_data", []);
              }}
            />
            <Error field="venue_data" />
          </div>
          <div className="form-group">
            <label>
              Staff <span className="red">*</span>
            </label>
            <Select
              value={staffData?.filter(val => {
                return values?.staff_data?.includes(val.value);
              })}
              isMulti
              name="staff_data"
              disabled={!values?.venue_data}
              options={staffData}
              className="basic-multi-select w-100"
              classNamePrefix="select"
              onChange={selected => {
                if (selected && selected !== null) {
                  setFieldValue(
                    "staff_data",
                    selected.map(val => val.value)
                  );
                } else {
                  setFieldValue("staff_data", []);
                }
              }}
            />
            <Error field="staff_data" />
          </div>
          <div className="form-group">
            <label>
              Services <span className="red">*</span>
            </label>
            <Select
              value={serviceData?.filter(val => {
                return values?.service_data?.includes(val.value);
              })}
              isMulti
              name="service_data"
              options={serviceData}
              className="basic-multi-select w-100"
              classNamePrefix="select"
              onChange={selected => {
                if (selected && selected !== null) {
                  setFieldValue(
                    "service_data",
                    selected.map(val => val.value)
                  );
                } else {
                  setFieldValue("service_data", []);
                }
              }}
            />
            <Error field="service_data" />
          </div>

          <div className="form-group">
            <label>
              Image <span className="red">*</span>
            </label>
            <br />

            <input
              ref={fileInputRef}
              type="file"
              id="file_image"
              name="file_image"
              className="form-control react-form-input"
              accept="image/*"
              onChange={handleImageChange}
              onBlur={handleBlur}
            />
            <Error field="file_image" />

            {values?.file_image && (
              <>
                <br />
                
                  <img
                    src={
                      values?.file_image instanceof Blob
                        ? URL.createObjectURL(values?.file_image)
                        : `${process.env.REACT_APP_UPLOAD_DIR}${values?.file_image}`
                    }
                    className="profile-img"
                    alt="no"
                    width={100}
                  />
                <br />
              </>
            )}
          </div>
          <div className="form-group">
            <label>
              Status <span className="red">*</span>
            </label>
            <select
              className="detail-input-select custom-select"
              value={values?.status}
              name="status"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value={true}>Active</option>
              <option value={false}>Deactive</option>
            </select>
            <Error field="status" />
          </div>

          <div className="row">
            <div className="col-5 offset-2 offset-sm-4 col-sm-4">
              <Button
                onClick={e => RoomHandler(e)}
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={isValid ? false : true}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
            </div>
            <div className="col-5 col-sm-4">
              <Button
                onClick={() => {
                  onClose();
                }}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading && <Loader />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(RoomModal);
