import { connect } from "react-redux";
import React, { useRef, useState } from "react";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import enhancer from "./enhancer/SliderImageEnchaner";
import { compose } from "redux";
import { addImages, editImage } from "services/imageSliderServices";
import NavigationActions from "redux/navigation/actions";
import { useEffect } from "react";
import Loader from "components/Loader";
const { success, error } = NavigationActions;

const SliderImageModal = props => {
  const { isEdit, onClose } = props;
  const {
    values,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    isValid,
    setValues,
    token,
    handleSubmit,
    toggleRefresh,
    setFieldError,
    editData
  } = props;

  const fileInputRef = useRef(null);
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    isEdit && setValues({ ...editData });
  }, [editData]);

  const ImageHandler = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      var formData = new FormData();
      formData.append("file_image", values.file_image);
      setLoading(true);

      isEdit
        ? await editImage(token, values._id, formData).then(res => {
            if (res.success) {
              success(res.message);
              toggleRefresh(true);
              onClose();
              setLoading(false);
            } else {
              error(res.message);
              setLoading(false);
            }
            setLoading(false);
          })
        : await addImages(token, formData).then(res => {
            if (res.success) {
              success(res.message);
              toggleRefresh(true);
              onClose();
              setLoading(false);
            } else {
              error(res.message);
              setLoading(false);
            }
            setLoading(false);
          });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleImageChange = e => {
    if (e.target.files.length !== 1) {
      return;
    }
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    // Check if the selected file type is allowed
    if (!allowedTypes.includes(file.type)) {
      setFieldError(
        e.target.name,
        "Only .jpg, .jpeg, .png files are allowed."
      );
      // error("Only .jpg, .jpeg, .png files are allowed.");
      e.target.value = "";

      return;
    }
    // check if file is less than 2MB
    if (file.size > 2 * 1024 * 1024) {
      setFieldError(
        e.target.name,
        "File size should be less than 2MB."
      );
      // error("File size should be less than 2MB.");
      e.target.value = "";
      return;
    }

    // Set the selected file in Formik if the file type is valid
    setFieldValue(e.target.name, file);
    fileInputRef.current.value = "";
  };

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Image
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group">
            <label>
              Image <span className="red">*</span>
            </label>
            <br />
            <input
              ref={fileInputRef}
              type="file"
              id="file_image"
              name="file_image"
              className="form-control react-form-input"
              accept="image/*"
              onChange={handleImageChange}
              onBlur={handleBlur}
            />
            <Error field="file_image" />

            {values?.file_image && (
              <>
                <br />
                <img
                  src={
                    values?.file_image instanceof Blob
                      ? URL.createObjectURL(values?.file_image)
                      : `${process.env.REACT_APP_UPLOAD_DIR}${values?.file_image}`
                  }
                  className="profile-img"
                  alt="no"
                  width={100}
                />
                <br />
              </>
            )}
          </div>
          <div className="row">
            <div className="col-5 offset-2 offset-sm-4 col-sm-4">
              <Button
                onClick={(e) => ImageHandler(e)}
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={isValid ? false : true}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
            </div>
            <div className="col-5 col-sm-4">
              <Button
                onClick={() => {
                  onClose();
                }}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading && <Loader />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(SliderImageModal);
