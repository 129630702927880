import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import NavigationActions from "redux/navigation/actions";
import {
  changerequestfromweb,
  fetchRequestDataById
} from "services/requestServices";
import Loader from "components/Loader";
import approvedImage from "../../../assets/images/Group 571.svg";
import { ArrowLeft } from "react-feather";

const { success, error } = NavigationActions;

export const AcceptRequest = props => {
  const { request_id } = useParams();
  const { history } = props;
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const [requestDetails, setRequestDetails] = useState({
    venueName: "",
    roomName: "",
    serviceName: "",
    staff_id: "",
    room_id: "",
    service_id: ""
  });
  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetchRequestDataById(request_id);

        if (res.success) {
          if (res.data === true) {
            setApproved(true);
          } else {
            setRequestDetails({
              roomName: res.data?.room_id?.name || "",
              serviceName: res.data?.service_id?.name || "",
              room_id: res.data?.room_id?._id,
              service_id: res.data?.service_id?._id,
              venueName: res.data?.venue_data?.name || ""
            });

            const staffArr =
              res.data?.room_id?.staff_data || res.data?.staff_data || [];

            if (!staffArr?.length > 0) {
              setStaffList([]);
              error("No staff found for this room");
            }

            setStaffList(
              staffArr.map(staff => {
                return { value: staff._id, label: staff.name };
              })
            );
          }
        } else {
          error(res.data.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (request_id) fetchData();
  }, [request_id]);

  const formik = useFormik({
    initialValues: { comment: "", staff_id: "" },
    validationSchema: Yup.object({
      comment: Yup.string()
        .required("Please enter a comment.")
        .max(200, "Comment must be less than 200 characters"),
      staff_id: Yup.string().required("Please select a staff member.")
    }),
    onSubmit: async values => {
      setLoading(true);
      try {
        const res = await changerequestfromweb({
          staff_id: values.staff_id,
          room_id: requestDetails.room_id,
          service_id: requestDetails.service_id,
          request_id,
          comment: values.comment
        });

        if (res.success) {
          success(res.message);
          setApproved(true);
        } else {
          error(res.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  });

  if (loading) return <Loader />;

  return (
    <>
      <div className="form-container p-5" style={{ width: "440px" }}>
        {approved ? (
          <div className="approveddiv">
            <img src={approvedImage} alt="Approved" />
            <p className="mt-3">Request Accepted Successfully!</p>
          </div>
        ) : (
          requestDetails.roomName &&
          requestDetails.serviceName && (
            <form onSubmit={formik.handleSubmit}>
              <div className="rquesttittle">Hello</div>
              <p className="my-2">
                Room Number: {requestDetails.roomName} made a request
              </p>
              <p className="my-2">Service Name: {requestDetails.serviceName}</p>
              <hr />
              <div className="form-group">
                <label>
                  Staff: <span className="red">*</span>
                </label>
                <Select
                  value={staffList?.find(val => {
                    return formik.values?.staff_id === val.value;
                  })}
                  name="staff_id"
                  options={staffList}
                  className="basic-multi-select w-100"
                  classNamePrefix="select"
                  onChange={selected => {
                    if (selected && selected !== null) {
                      formik.setFieldValue("staff_id", selected.value);
                    } else {
                      formik.setFieldValue("staff_id", null);
                    }
                  }}
                />
                {formik.touched.staff_id && formik.errors.staff_id && (
                  <p className="text-danger">{formik.errors.staff_id}</p>
                )}
              </div>

              <div className="form-group">
                <label>
                  Comment: <span className="red">*</span>
                </label>
                <textarea
                  className="w-100 requesttextarea"
                  {...formik.getFieldProps("comment")}
                />
                {formik.touched.comment && formik.errors.comment && (
                  <p className="text-danger">{formik.errors.comment}</p>
                )}
              </div>
              <button className="btn approvebtn" type="submit">
                Accept
              </button>
            </form>
          )
        )}
      </div>
      <div className="my-5">
        <button className="btn" onClick={() => history.goBack()}>
          <ArrowLeft /> Back
        </button>
      </div>
    </>
  );
};
