import { api, handleResponse, handleError } from "./apiServices";

export const getStaff = (token, data) =>
  api(token)
    .post("api/staff/getAll", data)
    .then(handleResponse)
    .catch(handleError);

export const getVenueAllStaffData = (token, venueId) =>
  api(token)
    .get("api/staff/getVenueAllStaffData/" + venueId)
    .then(handleResponse)
    .catch(handleError);

export const getStaffAllData = token =>
  api(token)
    .get("api/staff/getAllstaffData")
    .then(handleResponse)
    .catch(handleError);

export const addStaff = (token, data) =>
  api(token)
    .post("api/staff/create", data)
    .then(handleResponse)
    .catch(handleError);

export const editStaff = (token, id, data) =>
  api(token)
    .put(`api/staff/edit/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const deleteStaff = (token, id) =>
  api(token)
    .delete(`api/staff/delete/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const changeStaffStatus = (token, id, data) =>
  api(token)
    .put(`/api/staff/update-status/${id}`, data)
    .then(handleResponse)
    .catch(handleError);
