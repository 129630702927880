import { withFormik } from "formik";
import * as Yup from "yup";
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/svg",
  "image/gif"
];
const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z /.]+$/, "Please enter valid name")
      .required("Please Enter Name"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Please Enter Email"),
    phone: Yup.string()
      .matches(/^\d+$/, "Please Enter valid Phone Number")
      .length(10, "Please Enter valid phone number")
      .required("Please Enter Phone Number"),
    file_image: Yup.mixed()
          .required("Please upload a image")
          .test(
            "fileFormat",
            "Only JPG, JPEG, PNG, SVG and GIF files are allowed.",
            value => value && SUPPORTED_FORMATS.includes(value?.type)
          ),
    status: Yup.string().required("Please select status"),
    venue_data: Yup.string().required("Please select venue data")
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    name: "",
    email: "",
    phone: "",
    file_image: "",
    status: true
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
