import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { markEscalatNotificationReadById } from "services/userNotificationServices";

const PopoverBlock = ({
  title,
  text,
  created,
  link,
  id,
  request_id,
  token,
  getNotificationData,
  userNotifications,
  setNotificationPopoverOpen
}) => {
  let history = useHistory();
  const markNotificationRead = async (id, n_link, redirect = true) => {
    await markEscalatNotificationReadById(token, id).then(data => {
      redirect && history.push(n_link);
      setNotificationPopoverOpen(false);
      userNotifications(token);
      getNotificationData(token);
    });
  };
  return (
    <div className="mail-popover-block">
      <div className="flex-x">
        <div
          className="flex-1"
          style={{ cursor: "pointer" }}
          onClick={() => markNotificationRead(id, link)}
        >
          <div className="fs-13 demi-bold-text">{title}</div>
          <div className="fs-11 medium-text">{text}</div>
        </div>
        <div className="d-flex flex-column align-end">
          <div className="fs-10 medium-text">{created}</div>
          <Link
            to={`/thresouldtime/edit/${request_id}`}
            onClick={() => markNotificationRead(id, link, false)}
            className="align-self-end btn btn-sm"
          >
            <i className="fa fa-edit"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PopoverBlock;
